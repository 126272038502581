<template>
  <NuxtLink v-if="href" :class="styles" :to="href">
    <LoadingSpinner v-if="loading" :class="iconStyles" />
    <component :is="icon" v-if="icon && !loading" :class="iconStyles" />
    <slot />
  </NuxtLink>

  <button v-else :class="styles" :disabled="disabled || loading" :type="type">
    <LoadingSpinner v-if="loading" :class="iconStyles" />
    <component :is="icon" v-if="icon && !loading" :class="iconStyles" />
    <slot />
  </button>
</template>

<script lang="ts" setup>
import type { Component } from 'vue'
import LoadingSpinner from '~/components/LoadingSpinner.vue'

const props = withDefaults(
  defineProps<{
    type?: 'button' | 'submit' | 'reset'
    href?: string
    outline?: boolean
    plain?: boolean
    color?: 'primary' | 'secondary' | 'danger' | 'success'
    icon?: Component
    iconPosition?: 'left' | 'right'
    iconColor?: 'light-gray' | 'white' | 'dark-gray'
    disabled?: boolean
    loading?: boolean
    size?: 'xs' | 'sm' | 'md'
  }>(),
  {
    type: 'button',
    href: undefined,
    outline: false,
    plain: false,
    color: 'primary',
    icon: undefined,
    iconPosition: 'left',
    iconColor: undefined,
    disabled: false,
    loading: false,
    size: 'md',
  }
)

const slots = useSlots()

const iconStyles = computed<string[]>(() => {
  const base = '-mx-0.5 -my-0.5 shrink-0 transition-all ease-in-out duration-150'

  const position = {
    left: 'order-first',
    right: 'order-last',
  }

  const colors = {
    'light-gray': 'text-gray-400 group-hover:text-gray-600',
    white: 'text-white',
    'dark-gray': 'text-gray-950 group-hover:text-gray-900',
  }

  const size = {
    xs: 'size-3',
    sm: 'size-4',
    md: 'size-5',
  }

  return [base, colors[props.iconColor ?? 'light-gray'], position[props.iconPosition ?? 'left'], size[props.size]]
})

const styles = computed<string[]>(() => {
  const base = [
    'relative inline-flex group items-center justify-center gap-x-2 rounded-md border font-semibold',
    'focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600',
    'disabled:opacity-50 disabled:cursor-not-allowed',
    'transition-all ease-in-out duration-150',
  ]

  const text = {
    xs: 'text-xs/6',
    sm: 'text-xs/6',
    md: 'text-sm/6',
  }

  const padding = {
    xs: props.icon && !slots.default ? 'p-1' : 'px-2 py-0.5',
    sm: props.icon && !slots.default ? 'p-1.5' : 'px-2 py-1',
    md: props.icon && !slots.default ? 'p-2' : 'px-3 py-1.5',
  }

  const outline = 'border-gray-200 text-gray-950 hover:bg-gray-100'

  const plain = 'border-transparent text-gray-950 hover:bg-gray-950/5'

  const solid = [
    'shadow-sm disabled:shadow-none',
    'after:absolute after:inset-0 after:rounded-[calc(theme(borderRadius.md)-1px)] after:shadow-[shadow:inset_0_1px_theme(colors.white/15%)]',
  ]

  const colors = {
    primary: 'text-white bg-gray-900 border-gray-950/90 hover:bg-gray-900/90',
    secondary: 'text-gray-950 bg-white border-gray-950/10 hover:bg-gray-50',
    danger: 'text-white bg-red-500 border-red-600/90 hover:bg-red-500/90',
    success: 'text-white bg-emerald-500 border-emerald-600/90 hover:bg-emerald-500/90',
  }

  return [
    ...base,
    text[props.size],
    padding[props.size],
    props.outline ? outline : '',
    props.plain ? plain : '',
    ...(props.outline || props.plain ? '' : solid),
    props.outline || props.plain ? '' : colors[props.color],
  ]
})
</script>
